<template>
  <v-navigation-drawer permanent rail style="border: none; width: 80px" class="bg-surface-variant">
    <v-list class="cursor-pointer d-flex justify-center align-center flex-column" style="gap: 12px; padding: 0 12px">
      <v-img :src="hasLogoSrc ? logoSrc : 'https://i.ibb.co/tBYcpTP/clearsale-logo.png'" :width="48" :height="48"
        style="margin: 24px 0 24px"></v-img>
      <NavigationRailItem v-for="navigationRailItem in navigationRailItems" :key="navigationRailItem.label"
        :item="navigationRailItem" @click="handleClickRailItem" @mouseenter="handleMouseEnterRailItem" />
    </v-list>
  </v-navigation-drawer>
  <v-navigation-drawer style="margin-left: 20px; border: none" class="bg-surface-variant px-4" v-model="drawerActive">
    <v-fade-transition>
      <v-list v-if="navigationDrawerItems" class="bg-surface-variant">
        <NavigationDrawerItem v-for="navigationDrawerItem in navigationDrawerItems.children"
          :key="navigationDrawerItem.label" :item="navigationDrawerItem" @click="handleClickDrawerItem">
        </NavigationDrawerItem>
      </v-list>
    </v-fade-transition>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
  import { onMounted, ref, defineEmits, defineExpose, computed } from "vue";
  import NavigationRailItem from "./NavigationRailItem.vue";
  import { NavigationItem } from "./INavigation";
  import NavigationDrawerItem from "./NavigationDrawerItem.vue";



  const navigationRailItems = ref<NavigationItem[]>([]);
  const navigationDrawerItems = ref<NavigationItem | null>();
  const drawerActive = ref(false);
  const logoSrc = ref("")

  const emit = defineEmits<{
    click: [item: NavigationItem];
  }>();

  function loadLinks(links: NavigationItem[]) {
    return navigationRailItems.value = links;
  }

  function loadLogo(logo: string) {
    return logoSrc.value = logo ?? "https://i.ibb.co/tBYcpTP/clearsale-logo.png";
  }

  function resetLinks() {
    return navigationRailItems.value = [];
  }

  const hasLogoSrc = computed(() => logoSrc.value !== "")

  defineExpose({
    loadLogo,
    loadLinks,
    resetLinks
  })

  function handleMouseEnterRailItem(payload: NavigationItem) {
    if (payload && payload.children.length > 0) {
      navigationDrawerItems.value = drawerNavigationItemsReducer(
        payload,
        "CHANGE_NAVIGATION_DRAWER",
        payload
      );
      drawerActive.value = true;

      return;
    }
    drawerActive.value = false;
  }

  function handleClickRailItem(payload: NavigationItem) {
    const itemClicked = { ...payload };
    navigationRailItems.value = navigationRailItems.value.map((item) => {
      if (item.label === itemClicked.label) {
        if (
          itemClicked.children.length > 0 &&
          itemClicked.children[0].children.length > 0
        ) {
          emit("click", itemClicked.children[0].children[0]);
          return item;
        }

        if (itemClicked.children.length > 0) {
          emit("click", itemClicked.children[0]);
        }
        return item;
      }

      return {
        ...item,
        children:
          item.children.length > 0
            ? item.children.map((child) => ({
              ...child,
              isActive: false,
              isOpen: false,
              children:
                child.children.length > 0
                  ? child.children.map((subChild) => ({
                    ...subChild,
                    isActive: false,
                  }))
                  : [],
            }))
            : [],
      };
    });

    navigationRailItems.value = railReducer(
      navigationRailItems.value,
      "CHANGE_ACTIVE_ITEM",
      itemClicked
    );

    if (navigationDrawerItems.value && itemClicked.children.length > 0) {
      if (itemClicked.children[0].children.length > 0) {
        itemClicked.children[0].children[0].isActive = true;
        itemClicked.children[0].isOpen = true;
        navigationDrawerItems.value = drawerNavigationItemsReducer(
          navigationDrawerItems.value,
          "CHANGE_ACTIVE_ITEM_SUB_CHILDREN",
          itemClicked.children[0].children[0]
        );

        return;
      }
      itemClicked.children[0].isActive = true;
      navigationDrawerItems.value = drawerNavigationItemsReducer(
        navigationDrawerItems.value,
        "CHANGE_ACTIVE_ITEM_CHILDREN",
        itemClicked.children[0]
      );

      return;
    }

    if (itemClicked.children.length === 0) {
      navigationDrawerItems.value = null;
    }

    emit("click", itemClicked);
  }

  function handleClickDrawerItem(payload: NavigationItem, TYPE: string) {
    const itemClicked = { ...payload };

    if (navigationDrawerItems.value) {
      if (TYPE === "CLICK_COLLAPSE_ITEM") {
        navigationDrawerItems.value = drawerNavigationItemsReducer(
          navigationDrawerItems.value,
          "CHANGE_COLLAPSE_ITEM",
          itemClicked
        );
      }

      if (TYPE === "CLICK_CHILD_ITEM") {
        navigationDrawerItems.value = drawerNavigationItemsReducer(
          navigationDrawerItems.value,
          "CHANGE_ACTIVE_ITEM_CHILDREN",
          itemClicked
        );

        navigationRailItems.value = railReducer(
          navigationRailItems.value,
          "CHANGE_ACTIVE_ITEM_BY_CHILD",
          itemClicked
        );

        navigationRailItems.value = navigationRailItems.value.map(
          (navigation) => {
            if (
              navigationDrawerItems.value &&
              navigation.label === navigationDrawerItems.value.label
            ) {
              return navigationDrawerItems.value;
            }
            return navigation;
          }
        );

        emit("click", itemClicked);
      }

      if (TYPE === "CLICK_SUBCHILD_ITEM") {
        navigationDrawerItems.value = drawerNavigationItemsReducer(
          navigationDrawerItems.value,
          "CHANGE_ACTIVE_ITEM_SUB_CHILDREN",
          itemClicked
        );

        navigationRailItems.value = railReducer(
          navigationRailItems.value,
          "CHANGE_ACTIVE_ITEM_BY_SUB_CHILD",
          itemClicked
        );

        navigationRailItems.value = navigationRailItems.value.map(
          (navigation) => {
            if (
              navigationDrawerItems.value &&
              navigation.label === navigationDrawerItems.value.label
            ) {
              return navigationDrawerItems.value;
            }
            return navigation;
          }
        );
        emit("click", itemClicked);
      }
    }
  }

  function drawerNavigationItemsReducer(
    state: NavigationItem,
    action: string,
    payload: NavigationItem
  ) {
    switch (action) {
      case "CHANGE_NAVIGATION_DRAWER":
        return { ...state, ...payload };

      case "CHANGE_COLLAPSE_ITEM":
        state.children = state.children.map((item) => {
          if (item.label === payload.label) {
            return { ...item, isOpen: !payload.isOpen };
          }
          return { ...item };
        });
        return { ...state };

      case "CHANGE_ACTIVE_ITEM_CHILDREN":
        state.children = state.children.map((item) => {
          if (item.label === payload.label) {
            item.isActive = true;
            return item;
          }
          item.isActive = false;
          item.children = item.children.map((child) => {
            return { ...child, isActive: false };
          });
          return item;
        });
        return { ...state, isActive: true };

      case "CHANGE_ACTIVE_ITEM_SUB_CHILDREN":
        state.children = state.children.map((item) => {
          if (item.children.some((child) => child.label === payload.label)) {
            item.isActive = true;

            item.children = item.children.map((child) => {
              if (child.label === payload.label) {
                return { ...child, isActive: true };
              }
              return { ...child, isActive: false };
            });

            return item;
          }

          item.isActive = false;

          item.children = item.children.map((child) => {
            return { ...child, isActive: false };
          });

          return item;
        });
        return { ...state, isActive: true };

      default:
        return state;
    }
  }

  function railReducer(
    state: NavigationItem[],
    action: string,
    payload: NavigationItem
  ) {
    switch (action) {
      case "CHANGE_ACTIVE_ITEM":
        state = state.map((item) => {
          item.isActive = item.label === payload.label;

          return item;
        });
        return state;

      case "CHANGE_ACTIVE_ITEM_BY_CHILD":
        state = state.map((item) => {
          const payloadExistInState = item.children.some(
            (child) => child.label === payload.label
          );

          if (payloadExistInState) {
            item.isActive = true;
          } else {
            item.isActive = false;
          }

          return item;
        });
        return state;

      case "CHANGE_ACTIVE_ITEM_BY_SUB_CHILD":
        state = state.map((item) => {
          const payloadExistInState = item.children.some((child) =>
            child.children.some((subChild) => subChild.label === payload.label)
          );

          if (payloadExistInState) {
            item.isActive = true;
          } else {
            item.isActive = false;
          }

          return item;
        });
        return state;

      default:
        return state;
    }
  }

  onMounted(() => {
    const route = window.location.pathname;

    navigationRailItems.value = navigationRailItems.value.map((item) => {
      if (item.path === route) {
        item.isActive = true;
      }

      if (item.children && item.children.length > 0) {
        item.children = item.children.map((child) => {
          if (child.path === route) {
            item.isActive = true;
            child.isActive = true;
            navigationDrawerItems.value = drawerNavigationItemsReducer(
              item,
              "CHANGE_NAVIGATION_DRAWER",
              item
            );
            const timeout = setTimeout(() => {
              drawerActive.value = true;
              clearTimeout(timeout);
            }, 100);
          }
          if (child.children) {
            child.children = child.children.map((subChild) => {
              if (subChild.path === route) {
                item.isActive = true;
                child.isActive = true;
                child.isOpen = true;
                subChild.isActive = true;

                navigationDrawerItems.value = drawerNavigationItemsReducer(
                  item,
                  "CHANGE_NAVIGATION_DRAWER",
                  item
                );
                const timeout = setTimeout(() => {
                  drawerActive.value = true;
                  clearTimeout(timeout);
                }, 100);
              }
              return subChild;
            });
          }

          return child;
        });
      }
      return item;
    });
  });
</script>
