import { colors } from './colors'

export const TYPE_THEME = {
	dark: {
		'primary': colors['primary-dark'],
		'primary-container': colors['primary-container-dark'],
		'on-primary': colors['on-primary-dark'],
		'on-primary-container': colors['on-primary-container-dark'],
		'inverse-primary': colors['inverse-primary-dark'],
		'secondary': colors['secondary-dark'],
		'secondary-container': colors['secondary-container-dark'],
		'on-secondary': colors['on-secondary-dark'],
		'on-secondary-container': colors['on-secondary-container-dark'],
		'tertiary': colors['tertiary-dark'],
		'tertiary-container': colors['tertiary-container-dark'],
		'on-tertiary': colors['on-tertiary-dark'],
		'on-tertiary-container': colors['on-tertiary-container-dark'],
		'surface': colors['surface-dark'],
		'surface-dim': colors['surface-dim-dark'],
		'surface-bright': colors['surface-bright-dark'],
		'surface-container-lowest': colors['surface-container-lowest-dark'],
		'surface-container-low': colors['surface-container-low-dark'],
		'surface-container': colors['surface-container-dark'],
		'surface-container-high': colors['surface-container-high-dark'],
		'surface-container-highest': colors['surface-container-highest-dark'],
		'surface-variant': colors['surface-variant-dark'],
		'on-surface': colors['on-surface-dark'],
		'on-surface-variant': colors['on-surface-variant-dark'],
		'inverse-surface': colors['inverse-surface-dark'],
		'inverse-on-surface': colors['inverse-on-surface-dark'],
		'background': colors['background-dark'],
		'on-background': colors['on-background-dark'],
		'error': colors['error-dark'],
		'error-container': colors['error-container-dark'],
		'on-error': colors['on-error-dark'],
		'on-error-container': colors['on-error-container-dark'],
		'outline': colors['outline-dark'],
		'outline-variant': colors['outline-variant-dark'],
		'shadow': colors['shadow-dark'],
		'surface-tint-color': colors['surface-tint-color-dark'],
		'surface-variant-bright': colors["surface-variant-bright-dark"],
		'scrim': colors['scrim-dark'],
		'success': colors['success-dark'],
		'on-success': colors['on-success-dark'],
		'success-container': colors['success-container-dark'],
		'on-success-container': colors['on-success-container-dark'],
		'warning': colors['warning-dark'],
		'on-warning': colors['on-warning-dark'],
		'warning-container': colors['warning-container-dark'],
		'on-warning-container': colors['on-warning-container-dark'],
		'information': colors['information-dark'],
		'on-information': colors['on-information-dark'],
		'information-container': colors['information-container-dark'],
		'on-information-container': colors['on-information-container-dark']
	},
	light: {
		'primary': colors['primary-light'],
		'primary-container': colors['primary-container-light'],
		'on-primary': colors['on-primary-light'],
		'on-primary-container': colors['on-primary-container-light'],
		'inverse-primary': colors['inverse-primary-light'],
		'secondary': colors['secondary-light'],
		'secondary-container': colors['secondary-container-light'],
		'on-secondary': colors['on-secondary-light'],
		'on-secondary-container': colors['on-secondary-container-light'],
		'tertiary': colors['tertiary-light'],
		'tertiary-container': colors['tertiary-container-light'],
		'on-tertiary': colors['on-tertiary-light'],
		'on-tertiary-container': colors['on-tertiary-container-light'],
		'surface': colors['surface-light'],
		'surface-dim': colors['surface-dim-light'],
		'surface-bright': colors['surface-bright-light'],
		'surface-container-lowest': colors['surface-container-lowest-light'],
		'surface-container-low': colors['surface-container-low-light'],
		'surface-container': colors['surface-container-light'],
		'surface-container-high': colors['surface-container-high-light'],
		'surface-container-highest': colors['surface-container-highest-light'],
		'surface-variant': colors['surface-variant-light'],
		'on-surface': colors['on-surface-light'],
		'on-surface-variant': colors['on-surface-variant-light'],
		'inverse-surface': colors['inverse-surface-light'],
		'inverse-on-surface': colors['inverse-on-surface-light'],
		'background': colors['background-light'],
		'on-background': colors['on-background-light'],
		'error': colors['error-light'],
		'error-container': colors['error-container-light'],
		'on-error': colors['on-error-light'],
		'on-error-container': colors['on-error-container-light'],
		'outline': colors['outline-light'],
		'outline-variant': colors['outline-variant-light'],
		'shadow': colors['shadow-light'],
		'surface-tint-color': colors['surface-tint-color-light'],
		'surface-variant-bright': colors["surface-variant-bright-light"],
		'scrim': colors['scrim-light'],
		'success': colors['success-light'],
		'on-success': colors['on-success-light'],
		'success-container': colors['success-container-light'],
		'on-success-container': colors['on-success-container-light'],
		'warning': colors['warning-light'],
		'on-warning': colors['on-warning-light'],
		'warning-container': colors['warning-container-light'],
		'on-warning-container': colors['on-warning-container-light'],
		'information': colors['information-light'],
		'on-information': colors['on-information-light'],
		'information-container': colors['information-container-light'],
		'on-information-container': colors['on-information-container-light']
	}
}

export type Theme = 'dark' | 'light'

export function getThemeByName(theme: Theme) {
	return TYPE_THEME[theme]
}
