<template>
  <div class="d-flex justify-center align-center flex-column" style="gap: 4px" @click="emit('click', props.item!)"
    @mouseenter="emit('mouseenter', props.item!)">
    <v-list-item :value="props.item?.label" :active="props.item?.isActive" class="rounded-pill"
      style="min-height: 32px; width: 56px" active-class="bg-secondary-container ">
      <slot name="subtitle">
        <div class="d-flex justify-center text-on-surface-variant" style="width: 24px; height: 24px">
          <v-icon :size="24" :icon="props.item!.icon"></v-icon>
        </div>
      </slot>
    </v-list-item>
    <div style="font-size: 12px" class="text-on-surface-variant">
      {{ props.item?.label }}
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, defineProps, defineEmits } from "vue";
  import { NavigationItem } from "@/components/NavigationMenu/INavigation";

  const props = defineProps({
    item: Object as PropType<NavigationItem>,
  });

  const emit = defineEmits<{
    click: [item: NavigationItem];
    mouseenter: [item: NavigationItem];
  }>();
</script>
