// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { getThemeByName, theme } from "@clearsale/material-ds"

// Vuetify
import { createVuetify, ThemeDefinition } from 'vuetify'
import { md3 } from 'vuetify/blueprints'

const variables = {
  ...theme.elevation,
  ...theme.motion,
  ...theme.radii,
  ...theme.state,
  ...theme.typography,
  'breakpoint-sm': theme.breakpoints.sm,
  'breakpoint-md': theme.breakpoints.md,
  'breakpoint-lg': theme.breakpoints.lg,
  'breakpoint-xl': theme.breakpoints.xl,
}

const dark: ThemeDefinition = {
  dark: true,
  colors: getThemeByName('dark'),
  variables
}

const light: ThemeDefinition = {
  dark: false,
  colors: getThemeByName('light'),
  variables
}

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    display: {
      thresholds: theme.breakpoints
    },
    blueprint: md3,
    theme: {
      defaultTheme: 'light',
      themes: {
        light,
        dark,
      },
    },
  }
)
