<template>
  <cs-menu></cs-menu>
  <div style="position: relative; z-index: 1; padding: 24px 40px">
    <cs-top-bar>
      <div slot="prepend" style="max-width: 360px">
        <v-sheet
          class="bg-surface-variant-bright w-100 rounded-pill px-4 d-flex align-center"
          height="40px"
          style="gap: 8px"
          elevation="0"
        >
          <v-icon icon="mdi-magnify"></v-icon>
          <input
            type="text"
            class="text-on-surface-variant w-100"
            style="outline: none"
            placeholder="Search on ClearSale"
          />
        </v-sheet>
      </div>
      <div slot="append">
        <div class="d-flex align-center" style="gap: 32px">
          <v-btn
            variant="text"
            density="compact"
            icon="mdi-help-circle-outline"
            color="grey-darken-4"
          ></v-btn>
          <v-btn
            variant="text"
            density="compact"
            icon="mdi-apps"
            color="grey-darken-4"
          ></v-btn>
          <v-btn
            variant="text"
            density="compact"
            icon="mdi-bell-outline"
            color="grey-darken-4"
          ></v-btn>
          <v-btn
            variant="text"
            density="compact"
            icon="mdi-account-circle-outline"
            color="grey-darken-4"
          ></v-btn>
        </div>
      </div>
      <!-- <div slot="title">title asdasd</div> -->
    </cs-top-bar>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { navigateToUrl } from "single-spa";

function handleChangeRouteTo(e: CustomEvent) {
  navigateToUrl(e.detail.linkTo);
}

onMounted(() => {
  const cMenuElement = document.querySelector("cs-menu");

  if (cMenuElement) {
    cMenuElement.menuItems = [];
    document.addEventListener("load-links", (e) => {
      cMenuElement.menuItems = (e as CustomEvent).detail;
    });

    cMenuElement.addEventListener("activeItem", handleChangeRouteTo);
  }
});
</script>
