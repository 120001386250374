import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex justify-center text-on-surface-variant",
  style: {"width":"24px","height":"24px"}
}
const _hoisted_2 = {
  style: {"font-size":"12px"},
  class: "text-on-surface-variant"
}

import { PropType } from "vue";
  import { NavigationItem } from "@/components/NavigationMenu/INavigation";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationRailItem',
  props: {
    item: Object as PropType<NavigationItem>,
  },
  emits: ["click", "mouseenter"],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const emit = __emit;

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex justify-center align-center flex-column",
    style: {"gap":"4px"},
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', props.item!))),
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (emit('mouseenter', props.item!)))
  }, [
    _createVNode(_component_v_list_item, {
      value: props.item?.label,
      active: props.item?.isActive,
      class: "rounded-pill",
      style: {"min-height":"32px","width":"56px"},
      "active-class": "bg-secondary-container "
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "subtitle", {}, () => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_icon, {
              size: 24,
              icon: props.item!.icon
            }, null, 8, ["icon"])
          ])
        ])
      ]),
      _: 3
    }, 8, ["value", "active"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(props.item?.label), 1)
  ], 32))
}
}

})