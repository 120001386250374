import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import '@clearsale/material-ds/dist/clearsale-material-ds/clearsale-material-ds.css'


loadFonts()



const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {

      });
    },
  },
  handleInstance(app) {

    app.use(vuetify)
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
