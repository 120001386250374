<template>
  <div ref="animatedElement">
    <div v-if="$props.item && $props.item.children.length > 0">
      <v-list-item v-if="$props.item && $props.item.children.length > 0" :value="props.item?.label"
        :active="props.item?.isActive" active-class="bg-secondary-container" :class="[
          'rounded-pill',
          ' w-100',
          props.item?.isActive ? 'active-collapse-item' : '',
        ]" @click.stop="emit('click', props.item!, 'CLICK_COLLAPSE_ITEM')">
        <slot name="subtitle">
          <div class="d-flex justify-space-between">
            <div>
              {{ $props.item?.label }}
            </div>

            <v-icon :icon="$props.item && $props.item.isOpen
              ? 'mdi-chevron-up'
              : 'mdi-chevron-down'
              "></v-icon>
          </div>
        </slot>
      </v-list-item>
      <v-expand-transition>
        <v-sheet v-show="$props.item && $props.item.isOpen" class="bg-surface-variant">
          <div v-for="child in props.item?.children" :key="child.label"
            class="cursor-pointer d-flex justify-center align-center flex-column w-100 px-4">
            <v-list-item :active="child.isActive" class="rounded-pill w-100" :value="child.label"
              active-class="bg-secondary-container" @click.stop="emit('click', child, 'CLICK_SUBCHILD_ITEM')">
              <slot name="subtitle">
                <div class="d-flex justify-space-between">
                  <div>{{ child.label }}</div>
                </div>
              </slot>
            </v-list-item>
          </div>
        </v-sheet>
      </v-expand-transition>
    </div>

    <div v-else class="cursor-pointer d-flex">
      <v-list-item :value="props.item?.label" :active="props.item?.isActive" class="rounded-pill w-100"
        active-class="bg-secondary-container" @click.stop="emit('click', props.item!, 'CLICK_CHILD_ITEM')">
        <slot name="subtitle">
          <div class="d-flex">
            <div>
              {{ $props.item?.label }}
            </div>
          </div>
        </slot>
      </v-list-item>
    </div>
  </div>
</template>

<script lang="ts" setup scoped>
  import { PropType, defineProps, defineEmits, ref, onMounted } from "vue";
  import { NavigationItem } from "@/components/NavigationMenu/INavigation";
  import gsap from "gsap";

  const animatedElement = ref(null);
  const props = defineProps({
    item: Object as PropType<NavigationItem>,
  });

  const emit = defineEmits<{
    click: [item: NavigationItem, type: string];
  }>();

  onMounted(() => {
    gsap.from(animatedElement.value, { opacity: 0, duration: 0.4 });
  });
</script>

<style lang="scss">
  .active-collapse-item {
    background-color: rgb(var(--v-theme-surface-variant));
    opacity: 0.3;
  }
</style>
