<template>
  <v-layout>
    <NavigationMenu ref="navigationMenuRef" @click="handleChangeRouteTo" v-if="links.length"></NavigationMenu>
  </v-layout>

</template>

<script lang="ts" setup>
  import NavigationMenu from "./components/NavigationMenu/NavigationMenu.vue";
  import { nextTick, onBeforeMount, onMounted, reactive, ref } from "vue";
  import { NavigationItem } from "./components/NavigationMenu/INavigation";
  import ShieldLogo from "./assets/logos/logo_shield.svg"
  import NexusLogo from "./assets/logos/logo_nexus.svg"
  import { customEvents, THEME_CHANGED_PAYLOAD } from "@clearsale/one-lib-events"
  import { useTheme } from "vuetify/lib/framework.mjs";

  const navigationMenuRef = ref<InstanceType<typeof NavigationMenu> | null>(null);

  function handleChangeRouteTo(e: NavigationItem) {
    customEvents.navigationRouterEvent.dispatch(e)
  }

  const links = ref<NavigationItem[]>([
  ])

  let theme = useTheme();

  function changeThemeOnEvent(event: CustomEvent<THEME_CHANGED_PAYLOAD>) {
    const themeName = event.detail;
    theme.global.name.value = themeName;
  }

  const loadAppLogo = {
    shield: ShieldLogo,
    nexus: NexusLogo
  }


  onMounted(() => {
    customEvents.navigationAppLinkEvent.listener(async (linksEventPayload) => {
      const detail = linksEventPayload.detail as NavigationItem[];
      if (detail.length) {
        links.value = detail

        await nextTick()
        if (navigationMenuRef.value)
          navigationMenuRef.value.loadLinks(links.value)
      }
    })

    customEvents.navigationAppLogoEvent.listener(async (logoEventPayload) => {
      const detail = logoEventPayload.detail as keyof typeof loadAppLogo;
      if (detail.length) {
        await nextTick()
        if (navigationMenuRef.value)
          navigationMenuRef.value.loadLogo(loadAppLogo[detail])
      }
    })
  })

  onBeforeMount(() => {
    customEvents.themeEvents.listener(changeThemeOnEvent);
    const storeThemeName = localStorage.getItem("theme");
    if (storeThemeName && storeThemeName?.length > 0) {
      theme.global.name.value = storeThemeName;
    }
  });
</script>
