import { breakpoints } from './breakpoint'
import { colors } from './colors'
import { elevation } from './elevation'
import { motion } from './motion'
import { radii } from './radii'
import { state } from './state'
import { typography } from './typography'

export * from "./theme"
export const theme = {
	breakpoints,
	colors,
	elevation,
	motion,
	radii,
	state,
	typography,
}
