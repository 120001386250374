import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref, computed } from "vue";
  import NavigationRailItem from "./NavigationRailItem.vue";
  import { NavigationItem } from "./INavigation";
  import NavigationDrawerItem from "./NavigationDrawerItem.vue";



  
export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationMenu',
  emits: ["click"],
  setup(__props, { expose: __expose, emit: __emit }) {

  const navigationRailItems = ref<NavigationItem[]>([]);
  const navigationDrawerItems = ref<NavigationItem | null>();
  const drawerActive = ref(false);
  const logoSrc = ref("")

  const emit = __emit;

  function loadLinks(links: NavigationItem[]) {
    return navigationRailItems.value = links;
  }

  function loadLogo(logo: string) {
    return logoSrc.value = logo ?? "https://i.ibb.co/tBYcpTP/clearsale-logo.png";
  }

  function resetLinks() {
    return navigationRailItems.value = [];
  }

  const hasLogoSrc = computed(() => logoSrc.value !== "")

  __expose({
    loadLogo,
    loadLinks,
    resetLinks
  })

  function handleMouseEnterRailItem(payload: NavigationItem) {
    if (payload && payload.children.length > 0) {
      navigationDrawerItems.value = drawerNavigationItemsReducer(
        payload,
        "CHANGE_NAVIGATION_DRAWER",
        payload
      );
      drawerActive.value = true;

      return;
    }
    drawerActive.value = false;
  }

  function handleClickRailItem(payload: NavigationItem) {
    const itemClicked = { ...payload };
    navigationRailItems.value = navigationRailItems.value.map((item) => {
      if (item.label === itemClicked.label) {
        if (
          itemClicked.children.length > 0 &&
          itemClicked.children[0].children.length > 0
        ) {
          emit("click", itemClicked.children[0].children[0]);
          return item;
        }

        if (itemClicked.children.length > 0) {
          emit("click", itemClicked.children[0]);
        }
        return item;
      }

      return {
        ...item,
        children:
          item.children.length > 0
            ? item.children.map((child) => ({
              ...child,
              isActive: false,
              isOpen: false,
              children:
                child.children.length > 0
                  ? child.children.map((subChild) => ({
                    ...subChild,
                    isActive: false,
                  }))
                  : [],
            }))
            : [],
      };
    });

    navigationRailItems.value = railReducer(
      navigationRailItems.value,
      "CHANGE_ACTIVE_ITEM",
      itemClicked
    );

    if (navigationDrawerItems.value && itemClicked.children.length > 0) {
      if (itemClicked.children[0].children.length > 0) {
        itemClicked.children[0].children[0].isActive = true;
        itemClicked.children[0].isOpen = true;
        navigationDrawerItems.value = drawerNavigationItemsReducer(
          navigationDrawerItems.value,
          "CHANGE_ACTIVE_ITEM_SUB_CHILDREN",
          itemClicked.children[0].children[0]
        );

        return;
      }
      itemClicked.children[0].isActive = true;
      navigationDrawerItems.value = drawerNavigationItemsReducer(
        navigationDrawerItems.value,
        "CHANGE_ACTIVE_ITEM_CHILDREN",
        itemClicked.children[0]
      );

      return;
    }

    if (itemClicked.children.length === 0) {
      navigationDrawerItems.value = null;
    }

    emit("click", itemClicked);
  }

  function handleClickDrawerItem(payload: NavigationItem, TYPE: string) {
    const itemClicked = { ...payload };

    if (navigationDrawerItems.value) {
      if (TYPE === "CLICK_COLLAPSE_ITEM") {
        navigationDrawerItems.value = drawerNavigationItemsReducer(
          navigationDrawerItems.value,
          "CHANGE_COLLAPSE_ITEM",
          itemClicked
        );
      }

      if (TYPE === "CLICK_CHILD_ITEM") {
        navigationDrawerItems.value = drawerNavigationItemsReducer(
          navigationDrawerItems.value,
          "CHANGE_ACTIVE_ITEM_CHILDREN",
          itemClicked
        );

        navigationRailItems.value = railReducer(
          navigationRailItems.value,
          "CHANGE_ACTIVE_ITEM_BY_CHILD",
          itemClicked
        );

        navigationRailItems.value = navigationRailItems.value.map(
          (navigation) => {
            if (
              navigationDrawerItems.value &&
              navigation.label === navigationDrawerItems.value.label
            ) {
              return navigationDrawerItems.value;
            }
            return navigation;
          }
        );

        emit("click", itemClicked);
      }

      if (TYPE === "CLICK_SUBCHILD_ITEM") {
        navigationDrawerItems.value = drawerNavigationItemsReducer(
          navigationDrawerItems.value,
          "CHANGE_ACTIVE_ITEM_SUB_CHILDREN",
          itemClicked
        );

        navigationRailItems.value = railReducer(
          navigationRailItems.value,
          "CHANGE_ACTIVE_ITEM_BY_SUB_CHILD",
          itemClicked
        );

        navigationRailItems.value = navigationRailItems.value.map(
          (navigation) => {
            if (
              navigationDrawerItems.value &&
              navigation.label === navigationDrawerItems.value.label
            ) {
              return navigationDrawerItems.value;
            }
            return navigation;
          }
        );
        emit("click", itemClicked);
      }
    }
  }

  function drawerNavigationItemsReducer(
    state: NavigationItem,
    action: string,
    payload: NavigationItem
  ) {
    switch (action) {
      case "CHANGE_NAVIGATION_DRAWER":
        return { ...state, ...payload };

      case "CHANGE_COLLAPSE_ITEM":
        state.children = state.children.map((item) => {
          if (item.label === payload.label) {
            return { ...item, isOpen: !payload.isOpen };
          }
          return { ...item };
        });
        return { ...state };

      case "CHANGE_ACTIVE_ITEM_CHILDREN":
        state.children = state.children.map((item) => {
          if (item.label === payload.label) {
            item.isActive = true;
            return item;
          }
          item.isActive = false;
          item.children = item.children.map((child) => {
            return { ...child, isActive: false };
          });
          return item;
        });
        return { ...state, isActive: true };

      case "CHANGE_ACTIVE_ITEM_SUB_CHILDREN":
        state.children = state.children.map((item) => {
          if (item.children.some((child) => child.label === payload.label)) {
            item.isActive = true;

            item.children = item.children.map((child) => {
              if (child.label === payload.label) {
                return { ...child, isActive: true };
              }
              return { ...child, isActive: false };
            });

            return item;
          }

          item.isActive = false;

          item.children = item.children.map((child) => {
            return { ...child, isActive: false };
          });

          return item;
        });
        return { ...state, isActive: true };

      default:
        return state;
    }
  }

  function railReducer(
    state: NavigationItem[],
    action: string,
    payload: NavigationItem
  ) {
    switch (action) {
      case "CHANGE_ACTIVE_ITEM":
        state = state.map((item) => {
          item.isActive = item.label === payload.label;

          return item;
        });
        return state;

      case "CHANGE_ACTIVE_ITEM_BY_CHILD":
        state = state.map((item) => {
          const payloadExistInState = item.children.some(
            (child) => child.label === payload.label
          );

          if (payloadExistInState) {
            item.isActive = true;
          } else {
            item.isActive = false;
          }

          return item;
        });
        return state;

      case "CHANGE_ACTIVE_ITEM_BY_SUB_CHILD":
        state = state.map((item) => {
          const payloadExistInState = item.children.some((child) =>
            child.children.some((subChild) => subChild.label === payload.label)
          );

          if (payloadExistInState) {
            item.isActive = true;
          } else {
            item.isActive = false;
          }

          return item;
        });
        return state;

      default:
        return state;
    }
  }

  onMounted(() => {
    const route = window.location.pathname;

    navigationRailItems.value = navigationRailItems.value.map((item) => {
      if (item.path === route) {
        item.isActive = true;
      }

      if (item.children && item.children.length > 0) {
        item.children = item.children.map((child) => {
          if (child.path === route) {
            item.isActive = true;
            child.isActive = true;
            navigationDrawerItems.value = drawerNavigationItemsReducer(
              item,
              "CHANGE_NAVIGATION_DRAWER",
              item
            );
            const timeout = setTimeout(() => {
              drawerActive.value = true;
              clearTimeout(timeout);
            }, 100);
          }
          if (child.children) {
            child.children = child.children.map((subChild) => {
              if (subChild.path === route) {
                item.isActive = true;
                child.isActive = true;
                child.isOpen = true;
                subChild.isActive = true;

                navigationDrawerItems.value = drawerNavigationItemsReducer(
                  item,
                  "CHANGE_NAVIGATION_DRAWER",
                  item
                );
                const timeout = setTimeout(() => {
                  drawerActive.value = true;
                  clearTimeout(timeout);
                }, 100);
              }
              return subChild;
            });
          }

          return child;
        });
      }
      return item;
    });
  });

return (_ctx: any,_cache: any) => {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_fade_transition = _resolveComponent("v-fade-transition")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_navigation_drawer, {
      permanent: "",
      rail: "",
      style: {"border":"none","width":"80px"},
      class: "bg-surface-variant"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, {
          class: "cursor-pointer d-flex justify-center align-center flex-column",
          style: {"gap":"12px","padding":"0 12px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_img, {
              src: hasLogoSrc.value ? logoSrc.value : 'https://i.ibb.co/tBYcpTP/clearsale-logo.png',
              width: 48,
              height: 48,
              style: {"margin":"24px 0 24px"}
            }, null, 8, ["src"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigationRailItems.value, (navigationRailItem) => {
              return (_openBlock(), _createBlock(NavigationRailItem, {
                key: navigationRailItem.label,
                item: navigationRailItem,
                onClick: handleClickRailItem,
                onMouseenter: handleMouseEnterRailItem
              }, null, 8, ["item"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_navigation_drawer, {
      style: {"margin-left":"20px","border":"none"},
      class: "bg-surface-variant px-4",
      modelValue: drawerActive.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((drawerActive).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_fade_transition, null, {
          default: _withCtx(() => [
            (navigationDrawerItems.value)
              ? (_openBlock(), _createBlock(_component_v_list, {
                  key: 0,
                  class: "bg-surface-variant"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigationDrawerItems.value.children, (navigationDrawerItem) => {
                      return (_openBlock(), _createBlock(NavigationDrawerItem, {
                        key: navigationDrawerItem.label,
                        item: navigationDrawerItem,
                        onClick: handleClickDrawerItem
                      }, null, 8, ["item"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})