import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex justify-space-between" }
const _hoisted_3 = { class: "d-flex justify-space-between" }
const _hoisted_4 = {
  key: 1,
  class: "cursor-pointer d-flex"
}
const _hoisted_5 = { class: "d-flex" }

import { PropType, ref, onMounted } from "vue";
  import { NavigationItem } from "@/components/NavigationMenu/INavigation";
  import gsap from "gsap";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationDrawerItem',
  props: {
    item: Object as PropType<NavigationItem>,
  },
  emits: ["click"],
  setup(__props, { emit: __emit }) {

  const animatedElement = ref(null);
  const props = __props;

  const emit = __emit;

  onMounted(() => {
    gsap.from(animatedElement.value, { opacity: 0, duration: 0.4 });
  });

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "animatedElement",
    ref: animatedElement
  }, [
    (_ctx.$props.item && _ctx.$props.item.children.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.$props.item && _ctx.$props.item.children.length > 0)
            ? (_openBlock(), _createBlock(_component_v_list_item, {
                key: 0,
                value: props.item?.label,
                active: props.item?.isActive,
                "active-class": "bg-secondary-container",
                class: _normalizeClass([
          'rounded-pill',
          ' w-100',
          props.item?.isActive ? 'active-collapse-item' : '',
        ]),
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (emit('click', props.item!, 'CLICK_COLLAPSE_ITEM')), ["stop"]))
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "subtitle", {}, () => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$props.item?.label), 1),
                      _createVNode(_component_v_icon, {
                        icon: _ctx.$props.item && _ctx.$props.item.isOpen
              ? 'mdi-chevron-up'
              : 'mdi-chevron-down'
              
                      }, null, 8, ["icon"])
                    ])
                  ])
                ]),
                _: 3
              }, 8, ["value", "active", "class"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_expand_transition, null, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_v_sheet, { class: "bg-surface-variant" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.item?.children, (child) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: child.label,
                      class: "cursor-pointer d-flex justify-center align-center flex-column w-100 px-4"
                    }, [
                      _createVNode(_component_v_list_item, {
                        active: child.isActive,
                        class: "rounded-pill w-100",
                        value: child.label,
                        "active-class": "bg-secondary-container",
                        onClick: _withModifiers(($event: any) => (emit('click', child, 'CLICK_SUBCHILD_ITEM')), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "subtitle", {}, () => [
                            _createElementVNode("div", _hoisted_3, [
                              _createElementVNode("div", null, _toDisplayString(child.label), 1)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["active", "value", "onClick"])
                    ]))
                  }), 128))
                ]),
                _: 3
              }, 512), [
                [_vShow, _ctx.$props.item && _ctx.$props.item.isOpen]
              ])
            ]),
            _: 3
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_v_list_item, {
            value: props.item?.label,
            active: props.item?.isActive,
            class: "rounded-pill w-100",
            "active-class": "bg-secondary-container",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (emit('click', props.item!, 'CLICK_CHILD_ITEM')), ["stop"]))
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "subtitle", {}, () => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$props.item?.label), 1)
                ])
              ])
            ]),
            _: 3
          }, 8, ["value", "active"])
        ]))
  ], 512))
}
}

})